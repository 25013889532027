/**
 * App Header
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Avatar_02, Avatar_03, Avatar_05, Avatar_06, Avatar_08, Avatar_09, Avatar_13, Avatar_17, Avatar_21, headerlogo, loginLogo, lnEnglish, lnFrench, lnGerman, lnSpanish } from '../../Entryfile/imagepath';
import { getUserName, getUserType, getProfilePicture, getTitle, getLogo, getReadableDate, getTokenCookie, getChatbotEnabled } from '../../utility';
import { getHeaderNotifications } from '../service';
import Widget from 'rasa-webchat';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: getUserName(),
      title: getTitle(),
      logo: getLogo(),
      notifications: [],
      showSearchBar: false,
    }

  }
  componentDidMount() {
    this.fetchNotificationList();
  }
  fetchNotificationList = () => {
    getHeaderNotifications().then((res) => {
      if (res.status === "OK") {
        this.setState({ notifications: res.data });
      }
    });
  }

 
  

  render() {
    const { location } = this.props
    let isEMPLOYEE = getUserType() == 'EMPLOYEE';
    const { logo, notifications, title, userName, showSearchBar } = this.state;
    let pathname = "location.pathname"
    let isSuperAdmin = getUserType() == 'SUPER_ADMIN';
    let isCompanyAdmin = getUserType() == 'COMPANY_ADMIN';
    return (
      <>
  {getChatbotEnabled() && isEMPLOYEE  ? (
        <Widget
          initPayload={"/greet"}
          socketUrl={"https://chatbot.workplus-hrms.com"}
          customData={{ "language": "en", "access_token": getTokenCookie() }} // arbitrary custom data. Stay minimal as this will be added to the socket
          title={"Workplus Chatbot"}
          killSession={true}
          params={{
            images: {
              dims: {
                width: 300,
                height: 200,
              }
            },
            storage: "session"
          }}
        />
        ) : (
          <></>
        )}

        <div className="header" style={{ right: "0px" }}>
          <nav id='navbg' className="navbar navbar-expand-lg">
            <div className="container-fluid navbar-container">
              {/* Logo */}
              <div className="header-left">
                {/* <a href="/app/main/dashboard" className="logo"> */}
                <a style={{ cursor: 'revert' }} >
                  <img src={logo} alt="WorkPlus" width={"110px"} height={"40px"} />
                </a>
              </div>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="main_nav">
                <nav className="navbar navbar-expand-lg  w-100">

                  <div className="collapse navbar-collapse" id="main_nav">
                  </div>
                </nav>
              </div>
              
              <ul className="navbar-nav ms-auto profile-nav align-items-center">
                <li className="nav-item notifDropdown align-items-center">

                  <a href="#" id='navText' className="notificationIconView dropdown-toggle nav-link notificationAction" data-toggle="dropdown">
                    <i className="fa fa-bell-o" />
                  </a>
                  <div style={{marginTop: '-6px'}} className="dropdown-menu dropdown-menu-new notifications">
                    <div className="topnav-dropdown-header">
                      <span className="notification-title">Notifications</span>
                    </div>
                    <div className="noti-content">
                      <ul aria-labelledby="navText" className="notification-list">
                        {notifications && notifications.length > 0 && notifications.map((notification, index) => {
                          return (
                            <li key={index} className="notification-message">
                              <Link to={`/app/main/notification`}>
                                <div className="media">
                                  <span className="avatar">
                                    <img alt={getUserName()} src={'data:image/jpeg;base64,' + getProfilePicture()} />
                                  </span>
                                  <div className="media-body">
                                    <p className="noti-details">
                                      <span className="noti-title">{notification.details}</span>
                                    </p>
                                    <p className="noti-time"><span className="notification-time">
                                      {getReadableDate(notification.createdOn)}
                                    </span>
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            </li>)
                        })}
                      </ul>
                    </div>
                    <div className="topnav-dropdown-footer">
                      <Link to={`/app/main/notification`}>View all Notifications</Link>
                    </div>
                  </div>
                </li>
                <li  className="nav-item dropdown has-arrow main-drop mainMenu">
                  <a  href="#" id='navText' className="dropdown-toggle nav-link" data-toggle="dropdown">
                    <span className="user-img"><img src={'data:image/jpeg;base64,' + getProfilePicture()} alt={getUserName()} />
                      <span className="status online" /></span>
                    <span  className='ml-3 username'>{getUserName()}</span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-new profile-dropdown">
                     <> <Link className="dropdown-item" to="/app/company-app/profile">My Profile</Link> </>
                    <a className="dropdown-item" href="#" onClick={() => {
                      this.props.logout()
                    }}>Logout</a>
                  </div>
                </li>
                <li className="user-details">
                  {/* <i className="fas fa-bell fa-lg"></i>  */}
                  {/* <div className="user-details">
            <div className='switchBtn'>
              <div className='circle'></div>
              <div className='bar'></div>
            </div>
          </div> */}

                </li>

              </ul>
            </div>
          </nav>
        </div>


      </>
    );
  }
}
export default Header;
