import { Table } from 'antd';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { getUserType, getEmployeeId, getPermission, verifyViewPermission, verifyApprovalPermission, verifyViewPermissionForTeam, getCompanyId } from '../../../utility';
import EmployeeDropdown from '../../ModuleSetup/Dropdown/EmployeeDropdown';
import LeaveEntitlementForm from './form';
import { getLeaveBalance } from './service';
import AccessDenied from '../../../MainPage/Main/Dashboard/AccessDenied';
const { Header, Body, Footer, Dialog } = Modal;
const isCompanyAdmin = getUserType() === 'COMPANY_ADMIN';
const isCompanyId = getCompanyId();
toast.configure();
export default class Leave extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      defaultEmployeeId: getEmployeeId(),
      defaultYear: this.props?.data ? new Date().getFullYear() : "",
      years: [],
      isIdeal: true,
      self: isCompanyAdmin ? 0 : 1,
      buttonState: true,
    };
  }
  componentDidMount() {
    let years = [];
    let count = isCompanyId == 32 ? new Date().getFullYear() + 1 : new Date().getFullYear();
    for (var i = 2020; i <= count; i++) { 
      years.push(i);
    }
    this.setState({
      years
    });
    if (!isCompanyAdmin) { this.fetchList(); }
  }
  fetchList = () => {
    const { isIdeal, defaultEmployeeId, defaultYear } = this.state
    if (!isIdeal) {
      return;
    }
    this.setState({
      isIdeal: false
    }, () => {
      console.log(!defaultEmployeeId, !defaultYear);
      if (!defaultYear || !defaultEmployeeId) {
        this.setState({
          data: [],
          showForm: false,
          isIdeal: true
        })
        return;
      }
      if (verifyViewPermission("LEAVE BALANCE")) {
        getLeaveBalance(defaultEmployeeId, defaultYear).then(res => {
          this.setState({
            isIdeal: true
          });
          console.log(res);
          if (res.status == "OK") {
            this.setState({
              data: res.data,
              showForm: false
            })
          } else {
            this.setState({
              data: [],
              showForm: false
            })
            toast.error(res.message);
          }

        })
      }
    });

  }
  updateSelf = () => {
    this.setState({
      self: this.state.self == 1 ? 0 : 1
    }, () => {
      this.fetchList();
    })
  }
  onEmployeeChange = (employeeId) => {
    this.setState({
      defaultEmployeeId: employeeId
    }, () => {
      this.fetchList();
    });

  }
  onYearChange = (year) => {
    console.log('cell excat : ', year)
    this.setState({
      defaultYear: year
    }, () => {
      this.fetchList();
    });

  }
  hideForm = () => {
    this.setState({
      showForm: false,
    })
  }
  editOpeningBalance(leaveTypeId, openingBalance) {
    this.setState({
      selectedLeaveType: leaveTypeId,
      openingBalance,
      showForm: true,
    });
  }
  
  handleButtonClick = () => {
    this.setState((prevState) => ({
      buttonState: !prevState.buttonState,
      preferredMethod: prevState.buttonState ? 'Self' : 'Team'
    }));
  };
  render() {
    const { data, years, defaultYear,buttonState } = this.state
    const tableData = data.details;
    const columns = [
      {
        title: 'Leave Type',
        render: (text, record) => {
          return <span>{record.leaveType?.name}</span>
        },
        sorter: true,
      },
      {
        title: 'Opening Balance',
        render: (text, record) => {
          return record.allowEditOpeningBalance && verifyApprovalPermission("LEAVE BALANCE") ? <span>{record.openingBalance} <i onClick={e => {
            this.editOpeningBalance(record.leaveType?.id, record.openingBalance)
          }} className="ml-2 fa fa-edit"></i></span> : <span>{record.openingBalance}</span>
        },
        sorter: false,
      },
      {
        title: 'Eligibility',
        dataIndex: 'eligibility',
        sorter: false,
      },
      {
        title: 'Total Eligible',
        sorter: false,
        dataIndex: 'totalEligible',
      },
      {
        title: 'Leave Availed',
        dataIndex: 'leaveAvailed',
        sorter: true,
      }, {
        title: 'Accrued',
        render: (text, record) => {
          return <span>{parseFloat(record.accrued).toFixed(2)}</span>
        },
        sorter: true,
      }, {
        title: 'Leave Balance',
        render: (text, record) => {
          return <span>{parseFloat(record.leaveBalance).toFixed(2)}</span>
        },
        sorter: true,
      },
    ]
    return (
      <>
        {verifyViewPermission("LEAVE BALANCE") && <>
          <div className="page-container content container-fluid">

            {/* Page Header */}
            <div className="tablePage-header">
              <div className="row pageTitle-section">
                <div className="col">
                  <h3 className="tablePage-title">Leave Balance</h3>
                </div>
                <div className="row leave-balance-table">
                  {/* {verifyViewPermissionForTeam("LEAVE") && !isCompanyAdmin && <>

                    <div style={{height: '30px', marginTop: '7px'}} class="self-btn col-md-4 btn-group btn-cust-group" role="group" aria-label="Basic example">
                      <button type="button" className={this.state.self == 1 ? 'btn btn-sm btn-primary btn-selected' : 'btn btn-sm btn-secondary'} onClick={e => {
                        this.updateSelf()
                      }} > Self </button>

                      <button type="button" className={this.state.self != 1 ? 'btn btn-sm btn-primary btn-selected' : 'btn btn-sm btn-secondary'} onClick={e => {
                        this.updateSelf()
                      }} > Team </button>
                    </div>
                  </>} */}

                  {verifyViewPermissionForTeam("LEAVE") && !isCompanyAdmin && <div className="col-sm-2" style={{marginRight: '25px',marginTop: '10px'}}>
                    <div onClick={e => {
                      this.updateSelf()
                      this.handleButtonClick()
                    }} className="toggles-btn-view" id="button-container">

                      <div id="my-button" className="toggle-button-element" style={{ transform: buttonState ? 'translateX(0px)' : 'translateX(80px)' }}>
                        <p className='m-0 self-btn'>{buttonState ? 'Self' : 'Team'}</p>

                      </div>
                      <p className='m-0 team-btn' style={{ transform: buttonState ? 'translateX(0px)' : 'translateX(-80px)' }}>{buttonState ? 'Team' : 'Self'}</p>
                    </div>

                  </div>}
                  {verifyViewPermissionForTeam("LEAVE BALANCE") && this.state.self != 1 && <div className="col-sm-4 col-md-4">
                    <EmployeeDropdown nodefault={false} permission={getPermission("LEAVE BALANCE", "VIEW")} onChange={e => {
                      this.onEmployeeChange(e.target.value)
                    }}></EmployeeDropdown>
                  </div>}
                  <div className="mb-2 col-sm-4 col-md-3">
                    <select value={defaultYear} className="form-control"
                      onChange={e => {
                        this.onYearChange(e.target.value)
                      }}>
                      <option value="" selected={true}>Year</option>
                      {years.map((year, index) => (
                        <option key={index} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            {/* /Page Header */}
            <div className="mt-2 row">
              <div className="col-md-12">
                <div className="mb-3 table-responsive">
                  {(verifyViewPermissionForTeam("LEAVE BALANCE") && (this.state.defaultEmployeeId == 0 || !tableData)) && <>
                    <div className="alert alert-warning alert-dismissible fade show" role="alert">
                      <span>Please select Employee.</span>
                    </div>
                  </>}
                  {(this.state.defaultEmployeeId != 0 || !verifyViewPermissionForTeam("LEAVE BALANCE")) && tableData && <>
                    <Table id='Table-style' className="table-striped"
                      style={{ overflowX: 'auto' }}
                      columns={columns}
                      // bordered
                      dataSource={[...tableData]}
                      rowKey={record => record.id}
                      pagination={false}
                    />
                  </>}
                </div>
              </div>
            </div>
          </div>
          <Modal enforceFocus={false} size={"md"} show={this.state.showForm && verifyApprovalPermission("LEAVE BALANCE")} onHide={this.hideForm} >
            <Header closeButton>
              <h5 className="modal-title">Edit Opening Balance</h5>
            </Header>
            <Body>
              <LeaveEntitlementForm updateList={this.fetchList} currentOpeningBalance={this.state.openingBalance} leaveTypeId={this.state.selectedLeaveType} year={this.state.defaultYear} employeeId={this.state.defaultEmployeeId}>
              </LeaveEntitlementForm>
            </Body>
          </Modal>
        </>}
        {!verifyViewPermission("LEAVE BALANCE") && <AccessDenied></AccessDenied>}
      </>
    );
  }
}
